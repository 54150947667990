export function stringFromDate(dte: Date, iso = true): string {
  /// iso =yyyy-mm-dd , sinon jj/mm/aaaa
  if (dte === null) return ''
  if (dte === undefined) return ''
  const y = dte.getFullYear()
  const mo = dte.getMonth() + 1
  const d = dte.getDate()
  const mmo = (mo < 10 ? '0' : '') + mo
  const dd = (d < 10 ? '0' : '') + d
  if (iso) return `${y}-${mmo}-${dd}`
  else return `${dd}/${mmo}/${y}`
}
export function dateISOToDateFR(date: string): string {
  // fonction récupérant une date format ISO AAAA-MM-JJ et la transforme en JJ/MM/AAAA
  const [y, m, d] = date.split('-')
  return `${d}/${m}/${y}`
}
export function sqlDatetime(dte: Date): string {
  if (dte === null) return ''
  if (dte === undefined) return ''
  const y = dte.getFullYear()
  const mo = dte.getMonth() + 1
  const d = dte.getDate()
  const mmo = (mo < 10 ? '0' : '') + mo
  const dd = (d < 10 ? '0' : '') + d

  const h = dte.getHours()
  const mn = dte.getMinutes()
  const hh = (h < 10 ? '0' : '') + h
  const mmn = (mn < 10 ? '0' : '') + mn

  return `${y}-${mmo}-${dd} ${hh}:${mmn}`
}
export function datetimeFromString(stdatetime: string): Date | null {
  if (stdatetime == null) return new Date()
  const regex1 = /(\d*)-(\d*)-(\d*)\s+(\d*):(\d*)/g
  const dte = regex1.exec(stdatetime)
  if (dte) {
    const date = new Date(parseInt(dte[1]), parseInt(dte[2]) - 1, parseInt(dte[3]), parseInt(dte[4]), parseInt(dte[5]))
    return date
  }
  return null
}
export function dateFromString(stdate: string): Date {
  if (stdate == null) return new Date()
  const [year, month, day] = stdate.split('-')
  const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), 12)
  return date
}
export function sqlFormat(annee: number, mois: number, jour: number) {
  const a = annee.toString()
  let m = mois.toString()
  if (m.length === 1) m = '0' + m
  let j = jour.toString()
  if (j.length === 1) j = '0' + j
  return a + '-' + m + '-' + j
}
export function cleControle(): string {
  // la date est codée en base 31
  const dte = new Date()
  dte.setHours(12)
  const n = ((dte.getMonth() + 1) * 100 + dte.getDate()) * 10000 + dte.getFullYear()
  const cle = n.toString(31)
  return cle
}

export function openURL(url: string) {
  const open = window.open
  const win = open(url, '_blank')

  if (win) {
    win.focus()
    return win
  }
}
