import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/rapport',
    name: 'rapport',
    component: () => import('@/views/Rapport.vue')
  },
  {
    path: '/sites_chantiers_acces',
    name: 'sites_chantiers_acces',
    component: () => import('@/views/SitesChantiersAcces.vue')
  },
  {
    path: '/nettoyage',
    name: 'nettoyages',
    component: () => import('@/views/Nettoyage.vue')
  },
  {
    path: '/planning',
    name: 'planning',
    component: () => import('@/views/Planning.vue')
  },
  {
    path: '/salaries',
    name: 'salaries',
    component: () => import('@/views/gestionSalaries.vue')
  },
  {
    path: '/iframe/:page',
    name: 'iframe',
    component: () => import('@/views/IFrame.vue')
  },
  {
    path: '/anomalie',
    name: 'anomalie',
    component: () => import('@/views/Anomalie.vue')
  },
  {
    path: '/autorisations',
    name: 'autorisations',
    component: () => import('@/views/Autorisations.vue')
  },
  {
    path: '/ficheTOP',
    name: 'ficheTOP',
    component: () => import('@/views/FicheTOP.vue')
  },
  {
    path: '/checkliste',
    name: 'checkliste',
    component: () => import('@/views/Checkliste.vue')
  },
  {
    path: '/gestion_checkliste',
    name: 'GestionCheckliste',
    component: () => import('@/views/gestionCheckliste.vue')
  },
  {
    path: '/params',
    name: 'parametres',
    component: () => import('@/views/Configuration.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(async (to, from, next) => {
  if (to.path.startsWith('/login')) {
    next()
  } else {
    const id = parseInt(sessionStorage.getItem('Oxytec_Intranet_ut_id') ?? '0')
    if (id === 0) {
      next({ name: 'login' })
    } else {
      next()
    }
  }
})
export default router
