<template>
  <v-app class="grey lighten-4">
    <v-app-bar app clipped-left dense color="primary" dark>
      <v-app-bar-nav-icon v-if="$route.path != '/login'" @click.stop="drawer = !drawer" />
      <v-toolbar-title> <b>Oxytec</b></v-toolbar-title>
      <span class="mx-2"> {{ utilisateur.utilisateur }}</span>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="$route.path != '/login'">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">
              Gestion avancée
              <v-icon> mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item to="/salaries">
              <v-list-item-title><v-icon> mdi-account </v-icon> Salariés</v-list-item-title>
            </v-list-item>
            <v-list-item to="/autorisations">
              <v-list-item-title><v-icon> mdi-key </v-icon> Autorisations</v-list-item-title>
            </v-list-item>
            <v-list-item to="/sites_chantiers_acces">
              <v-list-item-title><v-icon> mdi-account-hard-hat </v-icon> Sites / Chantiers / Accès</v-list-item-title>
            </v-list-item>
            <v-list-item to="/iframe/machines">
              <v-list-item-title><v-icon> mdi-excavator </v-icon> Machines</v-list-item-title>
            </v-list-item>
            <v-list-item to="/params">
              <v-list-item-title><v-icon>mdi-tune</v-icon> Paramètres</v-list-item-title>
            </v-list-item>
            <v-list-item to="/about">
              <v-list-item-title><v-icon>mdi-information-outline</v-icon> À propos</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <v-navigation-drawer v-if="authentifie" v-model="drawer" app clipped dark width="250">
      <v-list dense>
        <v-list-item v-for="item in items" :key="item.title" :to="item.path">
          <v-list-item-action>
            <v-icon :color="item.icon_color">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list>
          <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon color="red">mdi-power</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <template v-if="utilisateur.utilisateur == ''">Déconnexion</template>
                <template v-else>{{ utilisateur.utilisateur }}</template>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-main v-resize="onResize" id="contenu">
      <router-view :content-height="contentHeight" :content-width="contentWidth"></router-view>
    </v-main>
    <confirm ref="confirm"></confirm>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue'
import axios from 'axios'
import confirm from '@/components/Confirm.vue'
import { nouveauJeton } from './plugins/jwt'

interface IConfirmer extends Vue {
  openDialogConfirm(title: string, message: string, options: object): Promise<boolean>
}
declare module 'vue/types/vue' {
  // augmentation pour Vue
  interface Vue {
    confirmation: (title: string, message: string, options: object) => Promise<boolean>
  }
}

export default Vue.extend({
  name: 'App',
  components: { confirm },
  data: () => ({
    unsubscribe: () => {},
    drawer: true,
    items: [
      { title: 'Rapport', icon_color: 'blue', icon: 'mdi-pencil', path: '/rapport' },
      { title: 'Nettoyage Wagons', icon_color: 'yellow', icon: 'mdi-train', path: '/nettoyage' },
      { title: 'Fiche TOP', icon_color: 'red', icon: 'mdi-alert', path: '/ficheTOP' },
      { title: 'Check-Liste', icon_color: 'blue', icon: 'mdi-checkbox-marked', path: '/checkliste' },
      { title: 'Anomalies', icon_color: 'lime', icon: 'mdi-alert-circle-outline', path: '/anomalie' },
      { title: 'Maintenance', icon_color: 'yellow', icon: 'mdi-wrench', path: '/iframe/maintenances' }
    ],
    utilisateur: { utilisateur: 'non authentifié', ut_id: 0, administrateur: false },
    contentWidth: 0,
    contentHeight: 0,
    authentifie: false
  }),
  provide() {
    return {
      confirmation: (titre: string, message: string, options: Record<string, unknown>) => (this.$refs.confirm as IConfirmer).openDialogConfirm(titre, message, options)
    }
  },
  methods: {
    logout() {
      this.authentifie = false
      this.utilisateur = { utilisateur: 'non authentifié', ut_id: 0, administrateur: false }
      sessionStorage.setItem('Oxytec_IntranetUSER', JSON.stringify(this.utilisateur))
      sessionStorage.setItem('Oxytec_Intranet_ut_id', '0')
      this.$router.push('/login')
    },
    onResize() {
      this.contentWidth = window.innerWidth
      this.contentHeight = window.innerHeight - 48
      if (this.drawer) {
        this.contentWidth -= 250
      }
      this.$store.commit('majContentHeight', { width: this.contentWidth, height: this.contentHeight })
    },
    chargerApp() {
      if (this.authentifie) {
        const url = this.$store.state.ajaxurl + 'ajax_appli.php'
        axios
          .get(url, {
            params: {
              action: 'SALARIES'
            }
          })
          .then((response) => {
            this.$store.commit('majSalaries', response.data.salaries)
          })
        axios
          .get(this.$store.state.ajaxurl + 'ajax_acces_chantiers.php', {
            params: {
              action: 'CHARGER_ACCES_CHANTIERS'
            }
          })
          .then((response) => {
            this.$store.commit('majAccesChantiers', response.data.acces_chantiers)
          })
      }
    }
  },
  mounted() {
    this.onResize()
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case 'majUtilisateur':
          this.authentifie = state.utilisateur.ut_id != 0
          this.utilisateur = { ...state.utilisateur }
          this.chargerApp()
          break
      }
    })
    this.$store.commit('majUtilisateur', JSON.parse(sessionStorage.getItem('Oxytec_IntranetUSER') ?? '{"utilisateur":"","ut_id":0,"administrateur":false}'))
    this.$store.commit('majAcls', JSON.parse(sessionStorage.getItem('Oxytec_Intranet_acls') ?? '[]'))
    this.chargerApp()
  },
  beforeDestroy() {
    this.unsubscribe()
  }
})
</script>
<style>
v-list-item--dense .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list--dense .v-list-item .v-list-item__subtitle {
  font-size: inherit !important;
}
.v-btn.discret .v-btn__content {
  opacity: 0.3;
}
.v-btn.discret:hover .v-btn__content {
  opacity: 1;
}
</style>
