import Vue from 'vue'
import Vuex from 'vuex'
import { stringFromDate, cleControle } from '../plugins/variables'
import axios from 'axios'

const debug = process.env.NODE_ENV === 'development'
const cejour = new Date()
const jour1 = stringFromDate(new Date(cejour.getFullYear(), cejour.getMonth(), 1))
const jour2 = stringFromDate(new Date(cejour.getFullYear(), cejour.getMonth() + 1, 0))

const utilisateur = {
  user: 'non authentifié',
  ut_id: 0,
  administrateur: false
}
interface Machine {
  mac_id: number
  mac_desi: string
  mac_libelle: string
  mac_categorie: string
}
export type tAccesChantier = {
  site_id: number
  site_nom: string
  ch_id: number
  ch_nom: string
  ac_code_utilisateur: string
  ac_mdp: string
  ac_page_nettoyage_wagon: string
}
export type Chantier = { ch_id: number; ch_nom: string }
export interface IUtilisateur {
  ut_id: number
  ut_nom: string
  ut_prenom: string
  ut_date_entree: string | null
  ut_date_sortie: string | null
  ut_societe: string
  autorisation?: string[]
}
export type tMachine = {
  mac_id: number
  mac_desi: string
  mac_libelle: string
  mac_categorie: string
  mac_serie: string // immat
  ch_nom?: string
}

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    debug: debug,
    contentWidth: 0,
    contentHeight: 0,
    ajaxurl: debug ? 'http://localhost/oxytec/intranet_vuetify/php/' : './php/',
    utilisateur: utilisateur,
    jour1: jour1,
    jour2: jour2,
    salaries: [] as IUtilisateur[],
    machines: [] as Machine[],
    chantiers: [] as Chantier[],
    categorieMachines: [] as string[],
    acls: [],
    acces_chantiers: [] as tAccesChantier[] // tous les acces du salarié
  },
  mutations: {
    majContentHeight(state, payload) {
      state.contentWidth = payload.width
      state.contentHeight = payload.height
    },
    majUtilisateur(state, utilisateur) {
      state.utilisateur = utilisateur
      sessionStorage.setItem('Oxytec_IntranetUSER', JSON.stringify(utilisateur))
      sessionStorage.setItem('Oxytec_Intranet_ut_id', String(utilisateur.ut_id))
    },
    majChantiers(state, chantiers) {
      state.chantiers = chantiers.slice()
    },
    majAccesChantiers(state, value) {
      state.acces_chantiers = value
    },
    async majSalaries(state, salaries) {
      state.salaries = salaries.slice()
    },
    majAcls(state, acls) {
      state.acls = acls
      sessionStorage.setItem('Oxytec_Intranet_acls', JSON.stringify(acls))
    },
    majPeriode(state, payload) {
      state.jour1 = payload.jour1
      state.jour2 = payload.jour2
    },
    async majMachines(state, machines) {
      state.machines = machines.slice()
      state.categorieMachines = []
      for (const m of state.machines) {
        if (!state.categorieMachines.includes(m.mac_categorie)) {
          state.categorieMachines.push(m.mac_categorie)
        }
      }
      state.categorieMachines.sort()
    }
  },
  actions: {
    chargerTables(context) {
      const url = context.state.ajaxurl + 'ajax_appli.php'
      axios
        .get(url, {
          params: {
            cle: cleControle(),
            action: 'TABLES'
          }
        })
        .then((response) => {
          if (response.data.machines) {
            context.commit('majMachines', response.data.machines)
          }
          if (response.data.salaries) {
            context.commit('majSalaries', response.data.salaries)
          }
          if (response.data.chantiers) {
            context.commit('majChantiers', response.data.chantiers)
          }
        })
    }
  },
  modules: {},
  getters: {
    // retourne tous les salariés présents dans la période jour1-jour2
    tousLesSalaries:
      (state) =>
      (jour1: string, jour2: string): IUtilisateur[] => {
        const salaries: IUtilisateur[] = []
        const strjour = jour1.substr(0, 10)
        const strjour2 = jour2.substr(0, 10)

        for (let n = 0; n < state.salaries.length; n++) {
          const salarie = state.salaries[n]
          if (salarie.ut_date_sortie && salarie.ut_date_sortie < strjour) continue
          if (salarie.ut_date_entree && salarie.ut_date_entree > strjour2) continue
          if (salarie.ut_societe != 'OXYTEC') continue
          salaries.push(salarie)
        }
        return salaries
      },
    /// filtre sur acces_chantiers.site_id == site_id
    chantiersAcces:
      (state) =>
      (site_id: number): { ch_id: number; ch_nom: string }[] => {
        const chantiers = [] as { ch_id: number; ch_nom: string }[]
        for (const acces of state.acces_chantiers) {
          if (acces.site_id == site_id) {
            if (!chantiers.find((c) => c.ch_id == acces.ch_id)) {
              /// pas de doublons
              chantiers.push({ ch_id: acces.ch_id, ch_nom: acces.ch_nom })
            }
          }
        }
        return chantiers
      },
    /// liste des sites de acces_chantiers
    sitesAcces: (state) => (): { site_id: number; site_nom: string }[] => {
      const sites = [] as { site_id: number; site_nom: string }[]
      for (const acces of state.acces_chantiers) {
        if (!sites.find((c) => c.site_id == acces.site_id)) {
          /// pas de doublons
          sites.push({ site_id: acces.site_id, site_nom: acces.site_nom })
        }
      }
      return sites
    }
  }
})
